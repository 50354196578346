import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/packages/recipes/src/layouts/recipe.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h5>{`Prep time: 45 min. `}<meta itemProp="prepTime" content="PT45M" /></h5>
    <h5>{`Cook time: 60 min. `}<meta itemProp="cookTime" content="PT60M" /></h5>
    <h5>{`Servings: `}<span itemProp="recipeYield">{`16`}</span></h5>
    <hr></hr>
    <h2>{`Ingredients`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Brown sugar, 2 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Sugar, 2 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Butter, 4 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Vanilla, 1/2 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Ripe bananas, 2</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Egg, 1</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Oatmeal, 2 cups</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Flour, 1 1/4 cups</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Baking powder, 1 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Salt, 1/4 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Cinnamon, 1/2 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Mix-ins, 1 cup</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Equipment`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="tool">Large mixing bowl</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Measuring cups + spoons</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Plastic wrap</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Cookie sheet</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Parchment paper</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Prep`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="step">Bring butter to room temperature</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Defrost and mash bananas</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Chop up any mix-ins</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Instructions`}</h2>
    <span itemProp="recipeInstructions">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Preheat oven to 350F.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Line cookie sheet with parchment paper.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Mix sugar and butter, then add the rest of wet ingredients.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Mix dry ingredients into the bowl.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Refrigerate the cookie dough for at least 15 minutes.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Shape cookies onto cookie sheet. Make sure to flatten since they will retain the shape.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Bake 10-20 minutes or until middle is set.`}</p>
        </li>
      </ol>
    </span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      